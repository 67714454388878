<template>
  <div class="about">
    <div class="banner-box">
      <div class="banner">
        <img v-if="aboutData.banners" :src="aboutData.banners[0].bgUrl" />
        <span>{{$store.state.lang === 'zh' ? aboutData.banners[0].titleLangZh : aboutData.banners[0].title}}</span>
      </div>
    </div>
    <p class="title">{{$store.state.lang=='zh'?aboutData.aboutTitleLangZh: aboutData.aboutTitle}}</p>
    <div class="content-box"> 
      <div class="content">
        <div class="imgs">
          <img :src="aboutData.aboutImgUrl" />
        </div>
        <span class="mark">{{$store.state.lang=='zh'?aboutData.aboutContentLangZh:aboutData.aboutContent}}
        </span>
      </div>
      <!-- <span
        >We have been operational executives and active investors through
        various economic cycles. We know that the most successful companies must
        have a long term outlook, and be built on a strong foundation in order
        to endure and thrive in any market condition. We have worked with many
        companies and understand where mistakes can be made and how world class
        companies are built. We bring our focus, experience, and know how to the
        areas where we invest and to our portfolio companies, working in close
        partnership with our executives for long term success.</span> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uri: require("@/assets/images/ABOUTUS.png"),
    };
  },
  computed:{
    aboutData(){
      const about = this.$store.state.mainContent.about
      const lang = this.$store.state.lang
      let obj = {}
      if(about && about.extend){
       
        obj = {
          ...about,
          ...JSON.parse(about.extend)
        }
         console.log(obj);

      }
      return obj
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-box {
  padding-top: 106px;
  .banner {
    position: relative;
    img {
      // height: 330px;
      width: 100%;
    }
    span {
      position: absolute;
      color: #fff;
      font-size: 50px;
      z-index: 1;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 75px;
    }
  }
}

.title {
  // font-family: Arial;
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin: 46px 0 34px 0;
}

.content-box {
  width: 85%;
  margin: 0 auto;
  padding-bottom: 37px;
  font-size: 16px;
}

.content {
  margin-bottom: 14px;
  display: flex;

  .imgs {
    margin-right: 28px;
    flex: 7;

    img {
      display: block;
      width: 100%;
    }
  }

  .mark {
    flex: 8;
    text-align: left;
  }
}
</style>
